// components/TwitterFollowModal.js
"use client";
import React, { useState, useEffect } from "react";
import Image from "next/image";
import "./TwitterFollow.css";

const TwitterFollowModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  // 30 saniye sonra modal'ı göster
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsModalVisible(true);
    }, 15000); // 30 saniye (30000 milisaniye)

    return () => clearTimeout(timer); // Bileşen unmounted olduğunda temizle
  }, []);

  // Modal'ı kapatmak için fonksiyon
  const closeModal = () => {
    setIsModalVisible(false);
  };

  // Boşluğa tıkladığında modal'ı kapatmak için overlay'e onClick ekle
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  // Linke tıkladığında modal'ı kapatmak için fonksiyon
  const handleLinkClick = () => {
    closeModal();
  };

  // Modal kapalıysa, boş bir fragment döndür
  if (!isModalVisible) return null;

  return (
    <div className="twitter-follow-modal-overlay" onClick={handleOverlayClick}>
      <div className="twitter-follow-modal-content">
        <button className="twitter-follow-close-button" onClick={closeModal}>
          ×
        </button>
        <a
          href="https://twitter.com/intent/follow?screen_name=uscmarkets"
          target="_blank"
          onClick={handleLinkClick}
        >
          <span className="twitter-follow-twitter-span">
            <Image
              src="/assets/logo-twitter.svg"
              alt="Twitter logosu"
              width={24}
              height={24}
              className="twitter-follow-twitter-logo"
            />
            Takip Edin: @UscMarkets
          </span>
        </a>
      </div>
    </div>
  );
};

export default TwitterFollowModal;
